// src/services/examInstructionsApi.js
import { centralBaseApi } from "../CentralBaseApi";

export const examTestLiveApi = centralBaseApi.injectEndpoints({
  reducerPath: 'examTestLiveApi',
  endpoints: (builder) => ({
    getTestLiveData: builder.query({
      query: (examId) => ({
        url: `/exam/online-test/${examId}`, // The API endpoint for fetching exam details by ID
        method: 'GET',
      }),
    }),
    addTestLiveData: builder.mutation({
      query: (payload) => ({
        url: '/exam/online-test/submit',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useAddTestLiveDataMutation,
  useGetTestLiveDataQuery, // Use the correct hook name format here
} = examTestLiveApi;
