import React, { useEffect } from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import './App.css';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isUserLoggedInCredentials } from './Redux/Features/AuthSlice';
import { useUpdateCartOnLogin } from './customHooks/updateCartOnLogin';
import { useAddToCartMutation } from './Redux/Api/cartApi';
import AdLeftBarCanvas from './components/AdSidebar/adleftbarcanvas'
import { useDispatch, useSelector } from 'react-redux';
import NetworkDetector from './hoc/NetworkDetector';
import { AppRoutes } from './routes/routes';
import { useGetPaperTypesQuery } from './Redux/Api/examApi/PaperTypeApi';
import { setCurrentPaperType } from './Redux/Features/exam/PaperTypeSlice';
import CaptchaModal from './components/Captcha/captchaModal';

function App() {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.root.auth);
  const cart = useSelector(state => state.root.cart);
  const { data: examCategory, isFetching } = useGetPaperTypesQuery();
  // const examCategory = examCategoryData?.data;

  const [updateCart] = useAddToCartMutation();

  useEffect(() => {
    if (!isFetching) {
      dispatch(setCurrentPaperType(examCategory));
    }
  }, [examCategory, dispatch, isFetching])

  useEffect(() => {
    if (!auth.token && (auth.isLoggedIn === true)) {
      dispatch(isUserLoggedInCredentials());
    }
  }, [auth?.isLoggedIn, dispatch, auth.token]);

  // Call the updateCartOnLogin function
  useUpdateCartOnLogin(auth, cart, updateCart);

  return (
    <>
      <Router>
        <div className="App">
          {(auth?.isLoggedIn && auth.token) ?
            <AdLeftBarCanvas />
            : ''
          }
       <AppRoutes />
       <CaptchaModal/>
        </div>
      </Router>
      <ToastContainer
        theme='dark' //light, colored, dark
        position="top-right"
        autoClose={3000}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </>
  );
}

export default NetworkDetector(App);

