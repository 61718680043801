import { centralBaseApi } from "../CentralBaseApi";

export const languageApi = centralBaseApi.injectEndpoints({
    reducerPath: 'languageApi',
    endpoints: (build) => ({
        getLanguages: build.query({
            query: () => ({
                url: '/exam/languages',
                method: 'GET',
            }),
        }),
        addLanguage: build.mutation({
            query: (payload) => ({
                url: '/exam/add-language',
                method: 'POST',
                body: payload,
            }),
        }),
        deleteLanguage: build.mutation({
            query: (id) => ({
                url: `/languages/${id}`,
                method: 'DELETE',
            }),
        }),
        updateLanguage: build.mutation({
            query: ({ id, payload }) => ({
                url: `/languages/${id}`,
                method: 'PUT',
                body: payload,
            }),
        }),
    }),
});

export const { useGetLanguagesQuery, useAddLanguageMutation, useDeleteLanguageMutation, useUpdateLanguageMutation } = languageApi;
