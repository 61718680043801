import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    addQuestion(state, action) {
      const { sectionId, question } = action.payload;
      if (!state[sectionId]) {
        state[sectionId] = [];
      }
      // state[sectionId].push(question);
      state[sectionId] = [...(state[sectionId] || []), question];
      console.log("reducer push action", state);
    },
    updateQuestion(state, action) {
      const { sectionId, question } = action.payload;
      const index = state[sectionId]?.findIndex((q) => q._id === question._id);
      if (index !== -1) {
        state[sectionId][index] = question;
      }
    },
    deleteQuestion(state, action) {
      const { sectionId, questionId } = action.payload;
      state[sectionId] = state[sectionId]?.filter((q) => q._id !== questionId);
    },
    setQuestions(state, action) {
      return action.payload; // Expected to be in the format { sectionId: [questions], ... }
    },
    resetQuestions() {
      return initialState;
    },
  },
});

export const { addQuestion, updateQuestion, deleteQuestion, setQuestions, resetQuestions } = questionsSlice.actions;

export default questionsSlice.reducer;
