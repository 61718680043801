// src/redux/features/stepSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  step: 0,
  visitedSteps: [], // Track visited steps
};

const stepSlice = createSlice({
  name: 'examCreateStep',
  initialState,
  reducers: {
    nextStep(state) {
      state.step += 1;
      if (!state.visitedSteps.includes(state.step)) {
        state.visitedSteps.push(state.step);
      }
    },
    prevStep(state) {
      state.step -= 1;
    },
    setStep(state, action) {
      state.step = action.payload;
      if (!state.visitedSteps.includes(state.step)) {
        state.visitedSteps.push(state.step);
      }
    },
    resetStep(state) {
      state.step = 0;
      state.visitedSteps = []; // Reset visited steps when starting over
    },
  },
});

export const { nextStep, prevStep, setStep, resetStep } = stepSlice.actions;
export default stepSlice.reducer;
