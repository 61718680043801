import React from "react";
import "./Skeleton.css";
import Spinner from "react-bootstrap/Spinner";

export default function Skeleton({ type }) {
  const SkeletonLoader = ({ count, Component }) =>
    Array.from({ length: count }).map((_, index) => <Component key={index} />);

  const FeedSkeleton = () => (
    <div className="postSk">
      <div className="postSkImg"></div>
      <div className="postSkInfo">
        <div className="postSkAvatar"></div>
        <div className="postSkDetail">
          <div className="postSkText"></div>
          <div className="postSkText sm"></div>
        </div>
      </div>
    </div>
  );

  const CatlogSkeleton = FeedSkeleton; // Reuse FeedSkeleton as they are identical.

  const TopSkeleton = () => (
    <div className="topSk">
      {[...Array(3)].map((_, index) => (
        <div key={index} className="topSkIcon"></div>
      ))}
      <div className="topSkImg"></div>
    </div>
  );

  const MenuSkeleton = () => (
    <div className="menuSk">
      {[...Array(4)].map((_, index) => (
        <div key={index} className="menuSkItem"></div>
      ))}
    </div>
  );

  const Circle = () => (
    <div className="circle">
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    </div>
  );

  const CustomLoading = () => (
    <div className="custom">
      <div className="balls">
        {[1, 2, 3].map((num) => (
          <div key={num} className={`ball ball${num}`}></div>
        ))}
      </div>
      <span className="customText">Loading...</span>
    </div>
  );

  const loaders = {
    feed: () => <SkeletonLoader count={12} Component={FeedSkeleton} />,
    catlog: () => <SkeletonLoader count={12} Component={CatlogSkeleton} />,
    top: () => <TopSkeleton />,
    menu: () => <MenuSkeleton />,
    circle: () => <Circle />,
    custom: () => <CustomLoading />,
  };

  return loaders[type] ? loaders[type]() : null;
}
