// src/services/examInstructionsApi.js
import { centralBaseApi } from "../CentralBaseApi";

export const examInstructionsApi = centralBaseApi.injectEndpoints({
  reducerPath: 'examInstructionsApi',
  endpoints: (builder) => ({
    getExamInstructions: builder.query({
      query: (id) => `/exam-instruction/${id}`, // Fetch all exam instructions
    }),
    createExamInstructions: builder.mutation({
      query: (newInstructions) => ({
        url: '/exam-instructions',
        method: 'POST',
        body: newInstructions,
      }),
    }),
    updateExamInstructions: builder.mutation({
      query: ({ id, updatedInstructions }) => ({
        url: `/exam-instructions/${id}`, // Use the id in the URL for update
        method: 'PUT',
        body: updatedInstructions,
      }),
    }),
    deleteExamInstructions: builder.mutation({
      query: (id) => ({
        url: `/exam-instructions/${id}`, // Use the id in the URL for delete
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetExamInstructionsQuery,
  useCreateExamInstructionsMutation,
  useUpdateExamInstructionsMutation,
  useDeleteExamInstructionsMutation,
} = examInstructionsApi;
