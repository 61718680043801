import { centralBaseApi } from './CentralBaseApi';

export const authApi = centralBaseApi.injectEndpoints({
  reducerPath: 'authApi',
  endpoints: (build) => {
    return {
      createUser: build.mutation({
        query: newUser => ({
          url: 'signup',
          method: 'POST',
          body: newUser,
        }),
      }),
      createUserByOtp: build.mutation({
        query: ({ user, tempToken }) => ({
          url: 'otsignup',
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${tempToken}`, // Add the tempToken to the Authorization header
            'Content-Type': 'application/json', // Ensure the content type is set to JSON
          },
          body: user,
        }),
      }),

      loginUser: build.mutation({
        query: loginData => ({
          url: 'signin',
          method: 'POST',
          body: loginData,
          credentials: "include",
        }),
        invalidatesTags: ['cat']
      }),
      loginUserByOtp: build.mutation({
        query: loginData => ({
          url: 'otsignin',
          method: 'POST',
          body: loginData,
          credentials: "include",
        }),
        invalidatesTags: ['cat']
      }),

      sendOtpByUser: build.mutation({
        query: loginData => ({
          url: 'otsend',
          method: 'POST',
          body: loginData,
        }),
      }),

      verifyByOtp: build.mutation({
        query: verifyUser => ({
          url: 'otverify',
          method: 'POST',
          body: verifyUser,
        }),
      }),

      logoutUser: build.mutation({
        query: () => ({
          url: 'signout',
          method: 'POST',
        }),

      }),
      overrideExisting: false,
    }
  }
})
export const {
  // useGetCategoryProductQuery,
  useCreateUserMutation,
  useVerifyByOtpMutation,
  useCreateUserByOtpMutation,
  useLoginUserMutation,
  useLoginUserByOtpMutation,
  useLogoutUserMutation,
  useSendOtpByUserMutation,
} = authApi;
