import { centralBaseApi } from "./CentralBaseApi";

export const userApi = centralBaseApi.injectEndpoints({
  reducerPath: 'userApi',
  endpoints: (build) => ({
    getUsersList: build.query({
      query: ({ page = 1, limit = 10, sort = 'username', search = '' }) => ({
        url: `/userlist?page=${page}&limit=${limit}&sort=${sort}&search=${search}`,
        method: 'get'
      }),
    }),
    toggleUserStatus: build.mutation({
      query: ({id, userStatus}) => ({
        url: `users/${id}/${userStatus}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['User'],
    }),
    deleteUser: build.mutation({
      query: (id) => ({
        url: `users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),
    getUser: build.query({
      query: () => ({
        url: '/user',
        method: 'get'
      }),
      keepUnusedDataFor: 60,
      providesTags: ['user']
    }),

    checkUsernameAvail: build.query({
      query: (username) => ({
        url: `/user/check?username=${username}`,
        method: 'get',
      }),
    }),
    // getProfileImg: build.query({
    //   query: (url) => ({
    //     url: `/${url}`,
    //     method: 'get',
    //     responseHandler: (response) => response.blob(), // Handle raw binary data
    //   }),
    //   transformResponse: async (blob) => {
    //     const url = URL.createObjectURL(blob); // Convert blob to an object URL
    //     return url;
    //   },
    // }),

    updateUser: build.mutation({
      query: (payload) => {
        const { formData } = payload;
        return {
          url: '/user',
          method: 'PUT',
          body: formData
        };
      },
    }),

    getOtherUserProfile: build.query({
      query: (payload) => {
        const { userId, username } = payload;
        const endpoint = username ? `/user/profile/username/${username}` : `/user/profile/${userId}`;
        return {
          url: endpoint,
          method: 'get',
        };
      },
      providesTags: ['profile'],
    }),
    overrideExisting: false,
  }),

});

export const {
  useGetUserQuery,
  useGetUsersListQuery, useToggleUserStatusMutation, useDeleteUserMutation,
  useCheckUsernameAvailQuery,
  useUpdateUserMutation,
  useGetOtherUserProfileQuery,
} = userApi;