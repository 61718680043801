import { centralBaseApi } from "./CentralBaseApi";

export const cronAgendaApi = centralBaseApi.injectEndpoints({
  reducerPath: 'cronAgendaApi',
  endpoints: (builder) => ({
    triggerAgendaWorker: builder.mutation({
      query: () => ({
        url: '/cron/agenda/start-worker',  // Matches the API route
        method: 'GET', // Using GET as defined in backend
      }),
    }),
  }),
});

export const { useTriggerAgendaWorkerMutation } = cronAgendaApi;
