import { centralBaseApi } from "./CentralBaseApi";

export const cartApi = centralBaseApi.injectEndpoints({
    reducerPath: 'cartApi',
    endpoints: (build) => ({
        getCartItems: build.query({
            query: () => ({
                url: '/user/getCartItems',
                method: 'get',
            }),
            keepUnusedDataFor: 60 * 60 * 24,
            providesTags: ['cart'],
        }),

        addToCart: build.mutation({
            query: (payload) => ({
                url: '/user/cart/addtocart',
                method: 'POST',
                body: payload,
            }),
        }),

        removeCartItem: build.mutation({
            query: (payload) => ({
                url: '/user/cart/removeItem',
                method: 'POST',
                body: payload,
            }),
            // Optimistic update to remove item without refetching
            // onQueryStarted: async (productId, { dispatch, queryFulfilled }) => {
            //     const patchResult = dispatch(
            //         cartApi.util.updateQueryData('getCartItems', undefined, (draft) => {
            //             delete draft.cartItems[productId];
            //         })
            //     );
            //     try {
            //         await queryFulfilled;
            //     } catch (error) {
            //         patchResult.undo(); // Rollback if the mutation fails
            //     }
            // },
            // invalidatesTags: ['cart'],
        }),

        modifyCartItem: build.mutation({
            query: (payload) => ({
                url: `/user/cart/${payload?.actionType}`,
                method: 'POST',
                body: payload,
            }),
            // Optimistic update for increment/decrement
            // onQueryStarted: async ({ productId, actionType }, { dispatch, queryFulfilled }) => {
            //     const quantityChange = actionType === 'increment' ? 1 : -1;
            //     const patchResult = dispatch(
            //         cartApi.util.updateQueryData('getCartItems', undefined, (draft) => {
            //             if (draft.cartItems[productId]) {
            //                 draft.cartItems[productId].quantity += quantityChange;
            //             }
            //         })
            //     );
            //     try {
            //         await queryFulfilled;
            //     } catch (error) {
            //         patchResult.undo(); // Rollback if the mutation fails
            //     }
            // },
            // invalidatesTags: ['cart'],
        }),

        overrideExisting: false,
    }),
});

export const {
    useGetCartItemsQuery,
    useAddToCartMutation,
    useRemoveCartItemMutation,
    useModifyCartItemMutation,  // Consolidated mutation for increment and decrement
} = cartApi;
