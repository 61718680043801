import { createSlice } from '@reduxjs/toolkit';
import CryptoJS from 'crypto-js';

const initialState = {
  user: {
    name: null,
    email: null,
  },
  token: null,
  isLoggedIn: false,
  isLoggedOut: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, { payload: { user, token } }) => {
      var sessionKey = CryptoJS.AES.encrypt(process.env.REACT_APP_BROWSER_CLOSE_OPEN_CHECK_NAME, process.env.REACT_APP_BROWSER_CLOSE_OPEN_CHECK_KEY).toString();
      sessionStorage.setItem(process.env.REACT_APP_BROWSER_CLOSE_OPEN_SESSION_NAME, sessionKey);
      if (user && token) {
        state.user = user;
      }
      state.token = token;
      state.isLoggedIn = true;
      localStorage.setItem('token', token);
    },
    unsetCredentials: state => {
      state.user = { name: null, email: null };
      state.token = null;
      state.isLoggedIn = false;
      state.isLoggedOut = true;
      localStorage.clear();
      sessionStorage.clear();
    },
    refreshCredentials: (state, { payload }) => {
      state.user = payload;
      state.isLoggedIn = true;
    },
    isUserLoggedInCredentials: state => {
      const token = localStorage.getItem('token');
      const user = JSON.parse(localStorage.getItem('user'));
      if (!(token && user)) {
        return { error: 'Failed to login.' }
      }
    },
    setUserData: (state, action) => {
      const { profilePicture, firstName, lastName, email } = action.payload;
      if (profilePicture) {
        state.user.profilePicture = profilePicture;
      }
      let nameUpdated = false;
      if (firstName && firstName !== state.user.firstName) {
        state.user.firstName = firstName;
        nameUpdated = true;
      }
      if (lastName && lastName !== state.user.lastName) {
        state.user.lastName = lastName;
        nameUpdated = true;
      }
      if (nameUpdated) {
        state.user.fullName = `${state.user.firstName || ''} ${state.user.lastName || ''}`.trim();
      }
      if (email) {
        state.user.email = email;
      }
    }
  },

});

export const {
  setCredentials,
  unsetCredentials,
  refreshCredentials,
  setUserData,
  isUserLoggedInCredentials } = authSlice.actions;

export default authSlice.reducer;
