import { centralBaseApi } from '../CentralBaseApi';

export const examAttemptApi = centralBaseApi.injectEndpoints({
  reducerPath: 'examAttempt',
  endpoints: (builder) => ({
    fetchExamReportById: builder.query({
      query: (id) => `/exam/exam-report/${id}`,
    }),
    fetchExamAttemptsList: builder.query({
      query: () => 'exam/exam-attempts/list',
    }),
    fetchExamPurchases: builder.query({
      query: () => '/exam/exam-purchases',
    }),
  }),
});

export const { 
  useFetchExamAttemptsQuery, 
  useFetchExamReportByIdQuery, 
  useFetchExamAttemptsListQuery,
  useFetchExamPurchasesQuery,
} = examAttemptApi;
