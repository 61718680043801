import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentUser: null, // Stores the currently logged-in user
    allUsers: [], // Stores the list of all users
};

export const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload; // Update the current user
        },
        resetCurrentUser: (state) => {
            state.currentUser = null; // Reset the current user only
        },
        setAllUserList: (state, action) => {
            state.allUsers = action.payload; // Store all users in Redux state
        },
    },
});

export const { setCurrentUser, resetCurrentUser, setAllUserList } = userSlice.actions;
export default userSlice.reducer;
