import { centralBaseApi } from '../CentralBaseApi';

export const questionsApi = centralBaseApi.injectEndpoints({
  reducerPath: 'questionsApi',
  endpoints: (build) => ({
    getQuestions: build.query({
      query: () => '/exam/questions',
    }),
    addQuestion: build.mutation({
      query: (payload) => ({
        url: '/exam/add-question',
        method: 'POST',
        body: payload,
      }),
    }),
    updateQuestion: build.mutation({
      query: ({ id, ...updatedQuestion }) => ({
        url: `/exam/questions/${id}`,
        method: 'PUT',
        body: updatedQuestion,
      }),
    }),
    deleteQuestion: build.mutation({
      query: (id) => ({
        url: `/exam/questions/${id}`,
        method: 'DELETE',
      }),
    }),
    getQuestionsBySectionId: build.query({
      query: (id) => ({
        url: `/exam/section-questions/${id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { 
  useGetQuestionsQuery,
  useGetQuestionsBySectionIdQuery,
  useAddQuestionMutation,
  useUpdateQuestionMutation,
  useDeleteQuestionMutation,
} = questionsApi;
