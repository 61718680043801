import { createSlice } from '@reduxjs/toolkit';
import { centralBaseApi } from '../../Api/CentralBaseApi';

const initialState = {
  trending: {
    data: [],
    status: 'idle',
    error: null,
  },
  'top-deals': {
    data: [],
    status: 'idle',
    error: null,
  },
  'engineering-exams': {
    data: [],
    status: 'idle',
    error: null,
  },
  'top-picks-upsc': {
    data: [],
    status: 'idle',
    error: null,
  },
  'top-picks-ssc': {
    data: [],
    status: 'idle',
    error: null,
  },
};

const examHomeSectionSlice = createSlice({
  name: 'examHomeSectionSlice',
  initialState,
  reducers: {
    resetSections(state) {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch for all sections
      .addMatcher(centralBaseApi.endpoints.getHomeSectionsCatData.matchPending, (state) => {
        Object.keys(state).forEach((key) => {
          state[key].status = 'loading';
        });
      })
      .addMatcher(centralBaseApi.endpoints.getHomeSectionsCatData.matchFulfilled, (state, action) => {
        console.log('API response:', action.payload); // Log to see actual 
        const sections = action?.payload?.data?.sections || {};  // Safely access the sections

        Object.keys(state).forEach((key) => {
          state[key].data = sections[key] || [];  // Safely handle missing sections
          state[key].status = 'succeeded';
        });
      })
      .addMatcher(centralBaseApi.endpoints.getHomeSectionsCatData.matchRejected, (state, action) => {
        Object.keys(state).forEach((key) => {
          state[key].status = 'failed';
          state[key].error = action?.error?.message || 'Something went wrong'; // Safe error handling
        });
      })

      // Fetch single section (for "More" button click)
      .addMatcher(centralBaseApi.endpoints.getOneHomeSectionCatlog.matchPending, (state, action) => {
        const sname = action?.meta?.arg?.sname;  // Safely retrieve sname
        if (state[sname]) {
          state[sname].status = 'loading';
        }
      })
      .addMatcher(centralBaseApi.endpoints.getOneHomeSectionCatlog.matchFulfilled, (state, action) => {
        const sname = action?.meta?.arg?.sname;  // Safely retrieve sname
        const data = action?.payload?.data || [];  // Safely retrieve data
        if (state[sname]) {
          state[sname].data = [...state[sname].data, ...data];  // Append new data
          state[sname].status = 'succeeded';
        }
      })
      .addMatcher(centralBaseApi.endpoints.getOneHomeSectionCatlog.matchRejected, (state, action) => {
        const sname = action?.meta?.arg?.sname;  // Safely retrieve sname
        if (state[sname]) {
          state[sname].status = 'failed';
          state[sname].error = action?.error?.message || 'Something went wrong'; // Safe error handling
        }
      });
  },
});

export const { resetSections } = examHomeSectionSlice.actions;

export default examHomeSectionSlice.reducer;
