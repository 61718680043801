import React, { useState } from 'react'; // Don't forget to import useState
import { useDispatch, useSelector } from 'react-redux';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { hideCaptcha, setCaptchaToken } from '../../Redux/Features/CaptchaSlice';
import { Modal, Button } from 'react-bootstrap'; // Import necessary components from React Bootstrap

const CaptchaModal = () => {
    const [captchaSolved, setCaptchaSolved] = useState(false);
    const [captchaError, setCaptchaError] = useState('');
    const dispatch = useDispatch();
    const isCaptchaVisible = useSelector((state) => state.captcha.isCaptchaVisible);

    const handleVerification = (token, ekey) => {
        dispatch(setCaptchaToken(token)); // Set CAPTCHA token in Redux store
        dispatch(hideCaptcha()); // Hide the modal after successful verification
        setCaptchaSolved(true);
        setCaptchaError(''); // Clear error on successful verification
    };

    const handleClose = () => {
        dispatch(hideCaptcha()); // Close the modal if needed
    };

    const handleError = () => {
        setCaptchaError('Captcha verification failed. Please try again.');
    };

    const handleExpire = () => {
        setCaptchaSolved(false);
        setCaptchaError('Captcha expired. Please complete it again.');
    };

    // If the modal is not visible, don't render anything
    if (!isCaptchaVisible) return null;

    return (
        <Modal show={isCaptchaVisible} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Suspicious Activity Detected</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Please complete the CAPTCHA to proceed.</p>
                <HCaptcha
                    sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
                    onVerify={(token,ekey) => handleVerification(token, ekey)}
                    onError={handleError}
                    onExpire={handleExpire}
                />
                {captchaError && <p className="text-danger">{captchaError}</p>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CaptchaModal;
