import { createSlice } from "@reduxjs/toolkit";

// Updated initialState to hold both sidebarHamburger and mobileFilter states
const initialState = {
    sidebarHamburger: false,
    mobileFilter: false,
    mobileSorting: false,
};

export const sidebarHamburgerSlice = createSlice({
    name: 'sidebarHamburger',
    initialState,
    reducers: {
        sideMenuOpenClose: (state) => {
            state.sidebarHamburger = !state.sidebarHamburger; // Toggle sidebarHamburger state
        },
        mobileFilterToggle: (state) => {
            state.mobileFilter = !state.mobileFilter; // Toggle mobileFilter state
        },
        mobileSortingToggle: (state) => {
            state.mobileSorting = !state.mobileSorting; // Toggle mobileFilter state
        },
        setMobileFilter: (state, action) => {
            state.mobileFilter = action.payload; // Set mobileFilter to a specific value
        },
    },
});

// Export actions
export const { sideMenuOpenClose, mobileFilterToggle, setMobileFilter, mobileSortingToggle } = sidebarHamburgerSlice.actions;

// Export reducer
export default sidebarHamburgerSlice.reducer;
