import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

export const postSlice = createSlice({
  name: 'postSlice',
  initialState,
  reducers: {
    setPost: (state, action) => {
      if (action.payload?.postList) {
        return action.payload.postList;
      }
      // If the payload doesn't contain postList, merge the new posts into the existing state
      return [...state, ...action.payload];
    },
    updateLikeCount: (state, action) => {
      const { postId, likeCount } = action.payload;
      console.log("payload", action.payload)
      const postIndex = state.findIndex((post) => post._id === postId);
      if (postIndex !== -1) {
        state[postIndex].like = likeCount;
      }
    },
  },
});

export const { setPost, updateLikeCount } = postSlice.actions;

export default postSlice.reducer;

