import { React } from 'react'
import { ListGroup, Offcanvas, Accordion, Image } from 'react-bootstrap';
import './adleftbarcanvas.css';
import {
  MdDashboard, MdAdd,
  MdNextWeek, MdDelete, MdEdit, MdSearch, MdViewModule, MdSettings, MdOutlineManageAccounts, MdOutlineSmartDisplay
} from 'react-icons/md';
import Adleftbaritem from '../AdMenuitem/adleftbaritem';
import { sideMenuOpenClose } from '../../Redux/Features/SidebarHamburgerSlice';
import { useDispatch, useSelector } from 'react-redux';
import LogoSpeed from "../../_assests/logo/logo-speed.png";
import { Link } from 'react-router-dom';
import { capitalizeFirstLetter } from '../../helpers/capitalizeFirstLetter';
import UserAvatar from '../userProfile/userAvatar/userAvatar';

const menuItems = [
  {
    menuItem_id: 1,
    toggleKey: false,
    menuIcons: <MdDashboard style={{ color: " var(--sp-logo-color)" }} />, name: 'Dashboard', exact: true, to: "/admin/dashboard"
  },

  {
    menuItem_id: 2,
    toggleKey: true,
    menuIcons: <MdSettings style={{ color: " var(--sp-logo-color)" }} />,
    name: "Article",
    exact: true,
    to: '#',
    subMenus: [
      { SubMenuIcons: <MdViewModule style={{ color: "green", fontSize: "16px", margin: "-3px 3px 0px 0px" }} />, name: "Article", to: '/article/home' },
      { SubMenuIcons: <MdEdit style={{ color: " var(--sp-logo-color)", fontSize: "14px", margin: "-3px 3px 0px 0px" }} />, name: "Edit Profile", to: '/article/edit' }],
  },
 
  {
    menuItem_id: 3,
    toggleKey: false,
    menuIcons: <MdOutlineSmartDisplay style={{ color: " var(--sp-logo-color)" }} />, name: 'Exam Store View', exact: true, to: "/admin/exam-page"
  },
  {
    menuItem_id: 4,
    toggleKey: true,
    menuIcons: <MdNextWeek style={{ color: " var(--sp-logo-color)" }} />,
    name: "Exam Management",
    exact: true,
    to: '#',
    subMenus: [
      { SubMenuIcons: <MdAdd style={{ color: "green", fontSize: "16px", margin: "-3px 3px 0px 0px" }} />, name: "Create Exam Paper", to: '/exam/create-exam-paper' },
      { SubMenuIcons: <MdEdit style={{ color: " var(--sp-logo-color)", fontSize: "14px", margin: "-3px 3px 0px 0px" }} />, name: "Exam Name List", to: '/exam/paper-type' },
      { SubMenuIcons: <MdEdit style={{ color: " var(--sp-logo-color)", fontSize: "14px", margin: "-3px 3px 0px 0px" }} />, name: "Exam Language", to: '/exam/add-language' },
      { SubMenuIcons: <MdEdit style={{ color: " var(--sp-logo-color)", fontSize: "14px", margin: "-3px 3px 0px 0px" }} />, name: "Exam Subject", to: '/exam/add-subject' },
    ],
  },
  {
    menuItem_id: 5,
    toggleKey: true,
    menuIcons: <MdOutlineSmartDisplay style={{ color: " var(--sp-logo-color)" }} />, name: 'Coupon Management', exact: true, to: "/coupon"
  },
  {
    menuItem_id: 6,
    toggleKey: true,
    menuIcons: <MdViewModule style={{ color: " var(--sp-logo-color)" }} />, name: 'For Create Exam Instructions', exact: true, to: "/exam/create-instructions"
  },
  {
    menuItem_id: 7,
    toggleKey: true,
    menuIcons: <MdViewModule style={{ color: " var(--sp-logo-color)" }} />, name: 'All User', exact: true, to: "/user"
  },
  {
    menuItem_id: 8,
    toggleKey: true,
    menuIcons: <MdViewModule style={{ color: " var(--sp-logo-color)" }} />, name: 'Cron Jobs', exact: true, to: "/cron/start-worker"
  },
];

const AdLeftBarCanvas = () => {
  const show = useSelector((state) => state?.sideMenuOpenClose?.sidebarHamburger);
  const {user} = useSelector(state => state.root.auth);
  const dispatch = useDispatch();
  return (
    <>
      <div className="ad-canlefttops">
        <Offcanvas show={show} onHide={() => dispatch(sideMenuOpenClose())} placment="start">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <div className='logo-with-text'>
                <div className='logo'>
                  <Link to="/" >
                    <img src={LogoSpeed} className="logoimage" alt="" />
                  </Link>
                </div>
                <div>Testspan</div>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <div className="ad-canlefttops">
            <div className="ad-sidesearch">
              <button className="ad-searchBtn">
                <MdSearch />
              </button>
              <input type="text" placeholder="search" />
            </div>
            <div className="ad-sidedivider"></div>
          </div>
          <Offcanvas.Body>
            <div className="ad-leftbarcanv">
              <Accordion flush>
                {
                  menuItems.map((menuItem, index) => (
                    <Adleftbaritem
                      key={index}
                      menuItem_id={menuItem.menuItem_id}
                      toggle_id={menuItem.toggleKey}
                      menuIcon={menuItem.menuIcons}
                      name={menuItem.name}
                      exact={menuItem.exact}
                      to={menuItem.to}
                      subMenus={menuItem.subMenus || []}
                    />
                  ))}
              </Accordion>
            </div>
          </Offcanvas.Body>
          <Accordion>
            <div className="ad-sidecanvbotm">
              <Accordion.Item eventKey="100">
                <Accordion.Header>
                  <div className="ad-sideFooter">
                  <UserAvatar className="ad-sideFooAvatar" alt="user-profile" />
                    <div className="ad-sideFooInfo">
                      <h5>{capitalizeFirstLetter(user?.firstName)} {capitalizeFirstLetter(user?.lastName)}</h5>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <ListGroup>
                    <ListGroup.Item action >
                      {user?.email}
                    </ListGroup.Item>
                    <ListGroup.Item action >
                      Designation: QA
                    </ListGroup.Item>
                  </ListGroup>
                </Accordion.Body>
              </Accordion.Item>
            </div>
          </Accordion>
        </Offcanvas>
      </div>
    </>
  )
}

export default AdLeftBarCanvas
