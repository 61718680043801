
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    examPage: {}
};

export const ExamPageSlice = createSlice({
  name: 'examPageSlice',
  initialState: initialState,
  reducers: {
    setExamPage: (state, action) => {
        return {
          ...state,
          examPage: action.payload.examPage}
    },
  },
  
});

export const {setExamPage } = ExamPageSlice.actions;

export default ExamPageSlice.reducer;