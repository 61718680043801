import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiUrl } from '../../config/urlConfig';
import { setCredentials, unsetCredentials } from '../Features/AuthSlice';
import { Mutex } from 'async-mutex';
import CryptoJS from 'crypto-js';
import { showCaptcha } from '../Features/CaptchaSlice';

const mutex = new Mutex();
let isRefreshing = false;

const baseQuery = fetchBaseQuery({
  keepUnusedDataFor: 60,
  refetchOnReconnect: true,
  baseUrl: apiUrl,
  credentials: 'include',
  prepareHeaders: async (headers, { getState, endpoint }) => {

    if (endpoint !== 'uploadToCdnCloud') {
      const token = getState().root.auth.token;
      if (token && !headers.has("Authorization")) {
        headers.set('Authorization', `Bearer ${token}`);
      }
    }

    return headers;
  },
});

const refreshAccessToken = async (api) => {
  if (isRefreshing) {
    // Another refresh request is already in progress, wait for it to finish
    await new Promise((resolve) => {
      const interval = setInterval(() => {
        if (!isRefreshing) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });
  } else {
    isRefreshing = true;

    try {
      const refreshResult = await baseQuery(
        {
          url: '/refreshtoken',
        },
        api,
      );

      if (refreshResult.data) {
        api.dispatch(setCredentials(refreshResult.data));
      } else if (refreshResult.error) {
        api.dispatch(unsetCredentials());
      }
    } finally {
      isRefreshing = false;
    }
  }
};

const decryptSessionKey = (encryptedText) => {
  const bytes = CryptoJS.AES.decrypt(encryptedText, process.env.REACT_APP_BROWSER_CLOSE_OPEN_CHECK_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};

const encryptSessionKey = (text) => {
  return CryptoJS.AES.encrypt(text, process.env.REACT_APP_BROWSER_CLOSE_OPEN_CHECK_KEY).toString();
};

const baseQueryWithReauth = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);

  const { dispatch, getState } = api;
  const auth = getState().root.auth;
  const { captchaToken } = getState().captcha;

  try {
    // Handle CAPTCHA requirement
    if (result.error?.data?.captchaRequired) {
      // Trigger CAPTCHA modal
      dispatch(showCaptcha());

      // Wait for CAPTCHA resolution with timeout
      const waitForCaptcha = new Promise((resolve, reject) => {
        const timeout = setTimeout(() => reject(new Error('CAPTCHA timeout')), 300000); // 1 min timeout
        const interval = setInterval(() => {
          const updatedCaptchaToken = getState().captcha.captchaToken;
          if (updatedCaptchaToken) {
            clearInterval(interval);
            clearTimeout(timeout);
            resolve(updatedCaptchaToken);
          }
        }, 100);
      });

      try {
        const token = await waitForCaptcha;

        // Add CAPTCHA token to headers
        args.headers = {
          ...args.headers,
          'x-captcha-token': token,
        };

        // Retry the request
        result = await baseQuery(args, api, extraOptions);
      } catch (err) {
        return {
          error: { status: 'CAPTCHA_FAILED', message: 'CAPTCHA verification failed or timed out.' },
        };
      }
    }

    let sessionKey = sessionStorage.getItem(process.env.REACT_APP_BROWSER_CLOSE_OPEN_SESSION_NAME);
    if (sessionKey && auth.token) {
      const decryptedSessionKey = decryptSessionKey(sessionKey);
      if (decryptedSessionKey !== process.env.REACT_APP_BROWSER_CLOSE_OPEN_CHECK_NAME) {
        if (auth.isLoggedIn === true) {
          await refreshAccessToken(api);
          result = await baseQuery(args, api, extraOptions);
          const newSessionKey = encryptSessionKey(process.env.REACT_APP_BROWSER_CLOSE_OPEN_CHECK_NAME);
          sessionStorage.setItem(process.env.REACT_APP_BROWSER_CLOSE_OPEN_SESSION_NAME, newSessionKey);
        } else {
          api.dispatch(unsetCredentials());
        }
      }
      //if token expire based on result 401 and 403
      if ((result.error && result.error.status === 401) || (result.error && result.error.status === 403)) {
        if (auth.isLoggedIn === true) {
          await refreshAccessToken(api);
          result = await baseQuery(args, api, extraOptions);
        }
      }
    } else {//to check browser open and close and refresh token first time
      if ((auth.isLoggedIn === true) && auth.token) { //check if not session key and user have token and logged in
        const sessionKey = CryptoJS.AES.encrypt(process.env.REACT_APP_BROWSER_CLOSE_OPEN_CHECK_NAME, process.env.REACT_APP_BROWSER_CLOSE_OPEN_CHECK_KEY).toString();
        sessionStorage.setItem(process.env.REACT_APP_BROWSER_CLOSE_OPEN_SESSION_NAME, sessionKey);
        await refreshAccessToken(api);
        result = await baseQuery(args, api, extraOptions);
      } else {
        // api.dispatch(unsetCredentials()); //this is comment to resolve cart reset issues
      }
    }
  } catch (error) {
    // Handle error
  }
  return result;
};

export const centralBaseApi = createApi({
  reducerPath: 'centralBaseApi',
  tagTypes: [
    'examInitialData', 'signup', 'posts', 'user',
    'category', 'profile', 'product', 'pages', 'examPages', 'order', 'address',
    'filterOptions', 'examPapers', 'payment'
  ],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});
