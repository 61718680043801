import { useEffect } from 'react';

export const useUpdateCartOnLogin = (auth, cart, updateCart) => {
  const ct = sessionStorage.getItem(process.env.REACT_APP_CART_ITEM_LOGIN_UPDATE_DB_NAME);
  const checkLength = cart.cartItems !== undefined && Object.keys(cart?.cartItems).length > 0 && auth?.token;
  useEffect(() => {
    if (auth.isLoggedIn === true && auth?.token) {
      const payload = {
        cartItems: cart.cartItems !== undefined && Object.keys(cart.cartItems).map((key, index) => {
          return {
            quantity: cart.cartItems[key].quantity,
            _id: cart.cartItems[key]._id,
            country: cart.cartItems[key].country,
            createdBy: cart.cartItems[key].createdBy,
            currency: cart.cartItems[key].currency,
            discount: cart.cartItems[key].discount,
            languages: cart.cartItems[key].languages,
            price: cart.cartItems[key].price,
            productImgUrl: cart.cartItems[key].productImgUrl,
            title: cart.cartItems[key].title,
          };
        }),
      };
      if (ct) {
        updateCart(payload).then(() => {
          // console.log("sync", payload);
        });
      }
    }
  }, [auth.isLoggedIn, auth?.token, cart.cartItems, ct, updateCart, checkLength]);
};
