import { centralBaseApi } from '../CentralBaseApi';

export const sectionsApi = centralBaseApi.injectEndpoints({
  reducerPath: 'sectionsApi',
  endpoints: (build) => ({
    addSection: build.mutation({
      query: (payload) => ({
        url: '/exam/addsection',
        method: 'POST',
        body: payload,
      }),
    }),
    getSections: build.query({
      query: () => ({
        url: '/exam/sections',
        method: 'GET',
      }),
    }),
    deleteSection: build.mutation({
      query: (id) => ({
        url: `/exam/sections/${id}`,
        method: 'DELETE',
      }),
    }),
    updateSection: build.mutation({
      query: ({ id, payload }) => ({
        url: `/exam/sections/${id}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getSectionByExamId: build.query({
      query: (id) => ({
        url: `/exam/section/${id}`,
        method: 'get',
      }),
    }),
  }),
});

export const { useAddSectionMutation, useGetSectionsQuery, useGetSectionByExamIdQuery, useDeleteSectionMutation, useUpdateSectionMutation } = sectionsApi;
