import React, { lazy, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PageNotFound from '../page404/404';
import Circle from '../components/Loader/Skeleton'; // Create a loading spinner component for suspense fallback

// Lazy load components
const Signup = lazy(() => import('../containers/signupPage/index'));
const Signin = lazy(() => import('../containers/SigninPage'));
const ResendOtp = lazy(() => import('../containers/SigninPage/resendOtp'));
const HomePage = lazy(() => import('../containers/homePage'));
const PrivateRoutes = lazy(() => import('../hoc/privateRoute'));
// const ProductDetailsPage = lazy(() => import( "../containers/ProductDetailsPage"));
const CartPage = lazy(() => import("../containers/CartPage"));
const CartCheckoutPage = lazy(() => import("../containers/CartCheckoutPage"));
const OrderPage = lazy(() => import("../containers/OrderPage"));
const OrderDetailsPage = lazy(() => import("../containers/OrderDetailsPage"));
const AdDashboard = lazy(() => import('../containers/Dashboard/dashboard'));
const UserEditView = lazy(() => import('../containers/UserProfile/userEdit'));
const Comment = lazy(() => import('../components/comments/comment'));
const ExamTestPage = lazy(() => import('../containers/ExamTestPage/ExamTestPage'));
const Favorites = lazy(() => import('../containers/Favorites/Favorites'));
const Notifications = lazy(() => import('../containers/Notifications/Notifications'));
const Aboutus = lazy(() => import('../containers/CompanyPolicy/Aboutus'));
const Faq = lazy(() => import('../containers/CompanyPolicy/Faq'));
const CancleReturnPolicy = lazy(() => import('../containers/CompanyPolicy/CancleReturnPolicy'));
const TermsAndConditions = lazy(() => import('../containers/CompanyPolicy/TermsAndConditions'));
const PrivacyPolicy = lazy(() => import('../containers/CompanyPolicy/PrivacyPolicy'));
const MultiStepForm = lazy(() => import('../components/exam/createExam/multiStepForm'));
const PaperTypeManagement = lazy(() => import('../components/exam/PaperType/paperTypeManagement'));
const AddExamLanguage = lazy(() => import('../components/Dashboard/AdLanguage/examLanguage'));
const ExamSubject = lazy(() => import('../components/Dashboard/AdSubject/subject'));
const ExamPage = lazy(() => import('../containers/ExamPage'));
const ProductDetails = lazy(() => import('../components/ProductDetails/ProductDetails'));
const ExamPaperListedPage = lazy(() => import('../containers/ExamListedPage/ExamPaperListedPage'));
const ExamPaperNewListedPage = lazy(() => import('../containers/ExamListedPage/ExamPaperNewListedPage'));
const ExamPaperSearchListedPage = lazy(() => import('../containers/ExamListedPage/ExamPaperNewListedPage'));
const CouponManager = lazy(() => import('../containers/Coupon/CouponManager'));
const ExamInstructionsPage = lazy(() => import('../containers/ExamInstruction/ExamInstructionPage'));
const UserExamPage = lazy(() => import('../containers/ExamAttemptView/UserExamPage'));
const UserList = lazy(() => import('../containers/UserProfile/userList'));
const ExamReportsPage = lazy(() => import('../containers/ExamAttemptView/ExamReportsPage'));
const SellTestSeries = lazy(() => import('../containers/SellTest/sellTestSeries'));
const ExamPurchases = lazy(() => import('../containers/ExamAttemptView/ExamPurchases'));
const AgendaTrigger = lazy(() => import('../containers/AgendaTrigger'));
// Add lazy imports for other components

export const AppRoutes = () => (
    <Suspense fallback={<Circle />}>
        <Routes>
            <Route path="/" exact element={<HomePage />} />
            <Route path="/signin" exact element={<Signin />} />
            <Route path="/signup" exact element={<Signup />} />
            <Route path="/resendotp" exact element={<ResendOtp />} />

            <Route element={<PrivateRoutes />}>

                <Route path="/cron/start-worker" exact element={<AgendaTrigger />} />
                <Route path="/comment" exact element={<Comment />} />

                <Route path='/admin/exam-page' element={<ExamPage />} />

                {/* <Route path="/article/edit" exact element={<ArticleEditPage />} /> */}

                <Route path="/admin/dashboard" element={<AdDashboard />} />

                <Route path="/account" exact element={<UserEditView />} />

                <Route path="/account/orders" element={<OrderPage />} />
                <Route path="/order_details/:orderId" element={<OrderDetailsPage />} />
                <Route path="/exam/online-test/:examId" exact element={<ExamTestPage />} />
                <Route path="/exam/create-instructions" element={<ExamInstructionsPage />} /> 

                <Route path="/exam/create-exam-paper" element={<MultiStepForm />} />
                <Route path="/exam/paper-type" element={<PaperTypeManagement />} />
                <Route path="/exam/add-language" element={<AddExamLanguage />} />
                <Route path="/exam/add-subject" element={<ExamSubject />} />

                {/* <Route path="/payment-result" element={<PaymentResult />} /> */}
                {/* <Route path="/payment/:id" exact element={<PaymentStatus />} /> */}

                <Route path="/favorites" element={<Favorites />} />
                <Route path="/notifications" element={<Notifications />} />

                <Route path="/coupon" element={<CouponManager />} />

                <Route path="/exam/exam-attempts" element={<UserExamPage />} />
                <Route path="/exam/exam-report/:id" element={<ExamReportsPage />} />
                <Route path="/exam/exam-purchases" element={<ExamPurchases />} />

                <Route path="/user" element={<UserList />} />


            </Route>

            {/* <Route path="/article/home" exact element={<ArticleHomePage />} />
            <Route path="/article/single" exact element={<ArticleSinglePage />} /> */}

            <Route path="/cart" exact element={<CartPage />} />
            <Route path="/checkout" element={<CartCheckoutPage />} />
           
            
            {/* <Route path="/:productSlug/:productId/" element={<ProductDetailsPage />} /> */}


            <Route path="/:slug" element={<ExamPaperListedPage />} />
            <Route path="/:examSlug/:examId/" element={<ProductDetails />} />
            <Route path="/exam-offer-list" element={<ExamPaperNewListedPage />} />
            <Route path="/exam-search-list" element={<ExamPaperSearchListedPage />} />

            <Route path="/aboutus" element={<Aboutus />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/cancellation-and-return-policy" element={<CancleReturnPolicy />} />
            <Route path="/term-and-conditions" element={<TermsAndConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />

            <Route path="/sell-test-series" element={<SellTestSeries />} />

            <Route path='/404' element={<PageNotFound />} />
            <Route path="*" element={<Navigate replace to="/404" />} />

        </Routes>
    </Suspense>
);
