export const serializeFilters = (filters) => {
    const serialized = {};
    
    for (const key in filters) {
        if (filters.hasOwnProperty(key) && filters[key] !== '') {
            serialized[key] = filters[key];
        }
    }
    return serialized; // Return an object that can be used in the body
};
