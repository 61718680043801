import { centralBaseApi } from "./CentralBaseApi";

function providesList(resultsWithIds, tagType) {
    return resultsWithIds
        ? [
            { type: tagType, id: 'LIST' },
            ...resultsWithIds.map(({ id }) => ({ type: tagType, id })),
        ]
        : [{ type: tagType, id: 'LIST' }]
}

export const postApi = centralBaseApi.injectEndpoints({
    reducerPath: 'postApi',
    endpoints: (build) => ({
        getPosts: build.query({
            query: () => 'posts',
            providesTags: (result) => providesList(result, 'posts'),
        }),

        addPost: build.mutation({
            query: (payload) => {
                return {
                    url: `post/create`,
                    method: 'POST',
                    body: payload
                };
            },
            invalidatesTags: [{ type: 'posts', id: 'LIST' }],
        }),

        getPost: build.query({
            query: (id) => `post/${id}`,
            providesTags: (result, error, id) => [{ type: 'posts', id }],
        }),

        updatePost: build.mutation({
            query: ({ id, ...patch }) => ({
                url: `post/${id}`,
                method: 'PATCH',
                body: patch,
            }),
            async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    centralBaseApi.util.updateQueryData('getPost', id, (draft) => {
                        Object.assign(draft, patch)
                    })
                )
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()

                    /**
                     * Alternatively, on failure you can invalidate the corresponding cache tags
                     * to trigger a re-fetch:
                     * dispatch(api.util.invalidateTags(['Post']))
                     */
                }
            },
        }),
        likePost: build.mutation({
            query: ({ id, like }) => ({
                url: `like/post/${id}`,
                method: 'PATCH',
                body: {like},
            }),
           
        }),
    }),
})

export const { useGetPostsQuery, useGetPostQuery, useAddPostMutation, useUpdatePostMutation, useLikePostMutation} = postApi