import { centralBaseApi } from "../CentralBaseApi";

export const examGlobalSearchApi = centralBaseApi.injectEndpoints({
    reducerPath: 'examGlobalSearchApi',
    endpoints: (build) => ({
        getSearchSuggestion: build.query({
            query: ({ searchTerm, page = 1, limit = 10 }) => ({
                url: `/search?q=${encodeURIComponent(searchTerm)}&page=${page}&limit=${limit}`,
                method: 'GET',
            }),
        }),
        getSearchSuggestionCat: build.query({
            query: (searchKey) => ({
                url: `/search/${searchKey}`,
                method: 'GET',
            }),
        }),
        overrideExisting: false,
    }),
});

export const {
    useGetSearchSuggestionQuery,
    useGetSearchSuggestionCatQuery,
} = examGlobalSearchApi;
