import { centralBaseApi } from "../CentralBaseApi";

export const paperTypeApi = centralBaseApi.injectEndpoints({
    endpoints: (build) => ({
        getPaperTypes: build.query({
            query: () => '/exam/getpapertype',
            providesTags: ['PaperType']
        }),
        addPaperType: build.mutation({
            query: (paperType) => ({
                url: '/exam/papertype',
                method: 'POST',
                body: paperType,
            }),
            invalidatesTags: ['PaperType']
        }),
        updatePaperType: build.mutation({
            query: ({ id, ...paperType }) => ({
                url: `/exam/papertype/${id}`,
                method: 'PUT',
                body: paperType,
            }),
            invalidatesTags: ['PaperType']
        }),
        deletePaperType: build.mutation({
            query: (id) => ({
                url: `/exam/papertype/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['PaperType']
        }),
    }),
});

export const {
    useGetPaperTypesQuery,
    useAddPaperTypeMutation,
    useUpdatePaperTypeMutation,
    useDeletePaperTypeMutation,
} = paperTypeApi;
