// Redux/Api/couponApi.js
import { centralBaseApi } from '../CentralBaseApi';

export const couponApi = centralBaseApi.injectEndpoints({
  reducerPath: 'examCoupon',
  endpoints: (build) => ({
    applyCoupon: build.mutation({
      query: ({ code, totalPrice, userId }) => ({
        url: 'coupon/validate-coupon',
        method: 'POST',
        body: { code, totalPrice, userId },
      }),
    }),
    getCoupons: build.query({
      query: () => '/coupons',
    }),
    getCoupon: build.query({
      query: (id) => `/coupons/${id}`,
    }),
    createCoupon: build.mutation({
      query: (newCoupon) => ({
        url: '/coupon',
        method: 'POST',
        body: newCoupon,
      }),
    }),
    updateCoupon: build.mutation({
      query: ({ id, ...data }) => ({
        url: `/coupons/${id}`,
        method: 'PUT',
        body: data,
      }),
    }),
    deleteCoupon: build.mutation({
      query: (id) => ({
        url: `/coupons/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useApplyCouponMutation,
  useGetCouponsQuery,
  useGetCouponQuery,
  useCreateCouponMutation,
  useUpdateCouponMutation,
  useDeleteCouponMutation } = couponApi;
