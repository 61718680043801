// Redux/Features/CaptchaSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isCaptchaVisible: false,
  captchaToken: null,
};

const captchaSlice = createSlice({
  name: 'captcha',
  initialState,
  reducers: {
    showCaptcha: (state) => {
      state.isCaptchaVisible = true;
    },
    hideCaptcha: (state) => {
      state.isCaptchaVisible = false;
    },
    setCaptchaToken: (state, action) => {
      state.captchaToken = action.payload;
    },
  },
});

export const { showCaptcha, hideCaptcha, setCaptchaToken } = captchaSlice.actions;
export default captchaSlice.reducer;
