import { centralBaseApi } from "../CentralBaseApi";

export const ExamPaperApi = centralBaseApi.injectEndpoints({
    reducerPath: 'examPaperApi',
    endpoints: (build) => ({
        createExamPaperInfo: build.mutation({
            query: (payload) => ({
                url: '/exam/addbasicinfo',
                method: 'POST',
                body: payload,
            }),
        }),
        getAllExamPaperInfo: build.query({
            query: () => ({
                url: `/exam/exampapers`,
                method: 'GET',
            }),
        }),
        getBasicExamPaperInfo: build.query({
            query: (id) => ({
                url: `/exam/exampaper/${id}`,
                method: 'GET',
            }),
        }),
        updateBasicExamInfo: build.mutation({
            query: ({ id, ...payload }) => ({
                url: `/exam/exam-papers/${id}`,
                method: 'PUT',
                body: payload,
            }),
        }),
        deleteBasicExamInfo: build.mutation({
            query: (id) => ({
                url: `/exam/exam-papers/${id}`,
                method: 'DELETE',
            }),
        }),
        getBasicExamDrafts: build.query({
            query: () => ({
                url: '/exam/all-draft-exampaper',
                method: 'GET',
            }),
        }),
        getBasicExamDraftsById: build.query({
            query: (id) => ({
                url: `/exam/draft-exampaper/${id}`,
                method: 'GET',
            }),
        }),
        deleteBasicExamDraftById: build.query({
            query: (id) => ({
                url: `/exam/draft-exampaper/${id}`,
                method: 'DELETE',
            }),
        }),
        getExamPaperBySlug: build.query({
            query: (slug) => ({
                url: `/exam/exam-papersl/${slug}`,
            }),
        }),
        getExamPaperDetailsById: build.query({
            query(payload) {
                const { examId } = payload;
                return {
                    url: `/exam/exam-paperepi/${examId}`,
                    method: 'GET',
                }
            },
        }),
        getExamImgSignature: build.query({
            query: () => ({
                url: '/exam/signature',
                method: 'GET',
            }),
        }),
        uploadToCdnCloud: build.mutation({
            query: ({ url, formData }) => ({
                url,
                method: 'POST',
                body: formData,
                credentials: 'omit',
            }),
        }),
        overrideExisting: false,
    }),
});

export const {
    useCreateExamPaperInfoMutation,
    useGetAllExamPaperInfoQuery,
    useGetBasicExamPaperInfoQuery,
    useUpdateBasicExamInfoMutation,
    useDeleteBasicExamInfoMutation,
    useGetBasicExamDraftsQuery,
    useGetBasicExamDraftsByIdQuery,
    useDeleteBasicExamDraftByIdQuery,
    useGetExamPaperBySlugQuery,
    useGetExamPaperDetailsByIdQuery,
    useUploadToCdnCloudMutation,
    useGetExamImgSignatureQuery,
} = ExamPaperApi;

