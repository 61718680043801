import React from 'react';
import defaultImage from '../../../_assests/default/default-profile-img.png';
import { useSelector } from 'react-redux';
import Skeleton from "../../Loader/Skeleton";
import { generatePublicUrl } from '../../../config/urlConfig';

const UserAvatar = ({ className = '', alt = "user-profile", userName = '' }) => {
  const user = useSelector((state) => state.root.auth);
  return (
    <div className={className}>
      {typeof user !== 'object' || Object.keys(user).length === 0 ? (
        <div className="circle-spin-skeleton">
          <Skeleton type="circle" />
        </div>
      ) : (
        <>
          <img
            src={user?.user?.profilePicture ? generatePublicUrl(user?.user?.profilePicture, user?.user?._id, 'profilePicture') : defaultImage}
            className="img-thumbnail"
            alt={alt}
            crossOrigin="anonymous"
          />
          {userName && <span>Kamlesh Kumar Yadav</span>}
        </>
      )}
    </div>
  );
};

export default UserAvatar;
