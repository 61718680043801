import React, { useState, useEffect } from "react";
import "./NetworkDetector.css";
import { toast } from "react-toastify";

const newtworkDetectorCheck = (ComposedComponent) => {
    const NetworkDetector = (props) => {
        const [isDisconnected, setIsDisconnected] = useState(false);

        useEffect(() => {
            handleConnectionChange();
            window.addEventListener("online", handleConnectionChange);
            window.addEventListener("offline", handleConnectionChange);
            return () => {
                window.removeEventListener(
                    "online",
                    handleConnectionChange
                );
                window.removeEventListener(
                    "offline",
                    handleConnectionChange
                );
            };
        }, []);

        const handleConnectionChange = () => {
            const condition = navigator.onLine ? "online" : "offline";
            if (condition === "online") {
                const webPing = setInterval(() => {
                    fetch("https://www.google.com", {
                        mode: "no-cors"
                    })
                        .then(() => {
                            setIsDisconnected(false);
                            clearInterval(webPing);
                        })
                        .catch(() => setIsDisconnected(true));
                }, 2000);
                return;
            }

            return setIsDisconnected(true);
        };

        return (
            <div>
                {isDisconnected && (
                    <div className="internet-error">
                        <p>Internet connection losted! {toast.error("Internet connection losted!",{toastId: ''})}</p>
                    </div>
                )}
                <ComposedComponent {...props} />
            </div>
        );
    };

    return NetworkDetector;
}
export default  newtworkDetectorCheck;