
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    address: [],
    orders: [],
    orderDetails: {},
    placedOrderId: null,
};
export const orderSlice = createSlice({
    name: 'orderSlice',
    initialState: initialState,
    reducers: {
        setUserAddress: (state, action) => {
            state.address = action.payload;
        },
        // all orders of a user
        setUserOrders: (state, action) => {
            state.orders = action.payload
        },
        //after placing successfull order by user
        setUserOrder: (state, action) => {
            state.placedOrderId = action.payload.order._id;
        },
        //after placing successfull order by user all information will store in this
        // single order with complete info and delivery location
        setUserOrderDetails: (state, action) => {
            state.orderDetails = action.payload.order
        },
    },

});

export const {
    setUserAddress, setUserOrders, setUserOrderDetails, setUserOrder } = orderSlice.actions;

export default orderSlice.reducer;
