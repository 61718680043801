import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  attempts: [],
  examReport:[],
  examPurchases:[],
  
  status: 'idle', // Possible values: 'idle', 'loading', 'succeeded', 'failed'
  error: null,
};

const examAttemptSlice = createSlice({
  name: 'examAttempt',
  initialState,
  reducers: {
    setExamAttempts: (state, action) => {
      state.attempts = action.payload;
    },
    setExamReports: (state, action) => {
      state.examReport = action.payload;
    },
    setExamPurchases: (state, action) => {
      state.examPurchases = action.payload;
    },
  },
});

// Actions
export const { setExamAttempts, setExamReports, setExamPurchases } = examAttemptSlice.actions;
// Reducer
export default examAttemptSlice.reducer;
