import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  createTransform
} from 'redux-persist';
import CryptoJS from 'crypto-js';
import storage from 'redux-persist/lib/storage';
import { setupListeners } from '@reduxjs/toolkit/query';
import authSlice from './Features/AuthSlice';
import SidebarHamburgerSlice from './Features/SidebarHamburgerSlice';
import captchaSlice from './Features/CaptchaSlice';
import { authApi } from './Api/AuthApi';
import { centralBaseApi } from './Api/CentralBaseApi';
import { cartApi } from './Api/cartApi';
import cartSlice from './Features/cartSlice';
import { userApi } from './Api/UserApi';
import userSlice from './Features/UserSlice';
import { orderApi } from './Api/OrderApi';
import orderSlice from './Features/OrderSlice';
import { postApi } from './Api/PostApi';
import postSlice from './Features/PostSlice';
import StepSlice from './Features/exam/StepSlice';
import ExamPaperSlice from './Features/exam/ExamPaperSlice';
import { ExamPaperApi } from './Api/examApi/ExamPaperApi';
import SectionSlice from './Features/exam/SectionSlice';
import { sectionsApi } from './Api/examApi/SectionsApi';
import QuestionSlice from './Features/exam/QuestionSlice';
import { questionsApi } from './Api/examApi/QuestionApi';
import PaperTypeSlice from './Features/exam/PaperTypeSlice';
import { paperTypeApi } from './Api/examApi/PaperTypeApi';
import { languageApi } from './Api/examApi/LanguageApi';
import ExamPageSlice from './Features/exam/ExamPageSlice';
import { examPageApi } from './Api/examApi/ExamPageApi';
import { examPaperCatlogApi } from './Api/examApi/ExamPaperCatlogApi';
import ExamPaperFilterSlice from './Features/exam/ExamPaperFilterSlice';
import ExamHomeSectionSlice from './Features/exam/ExamHomeSectionSlice';
import { examHomeSectionApi } from './Api/examApi/ExamHomeSectionApi';
import { examGlobalSearchApi } from './Api/examApi/ExamGlobalSearchApi';
import examPaperDetailsSlice from './Features/exam/ExamPaperDetailsSlice';
import examCouponSlice from './Features/exam/ExamCouponSlice';
import { couponApi } from './Api/examApi/ExamCouponApi';
import examInstructionsSlice from './Features/exam/ExamInstructionSlice';
import { examInstructionsApi } from './Api/examApi/ExamInstructionApi';
import ExamTestLiveSlice from './Features/exam/ExamTestLiveSlice';
import { examTestLiveApi } from './Api/examApi/ExamTestLiveApi';
import ExamAttemptSlice from './Features/exam/ExamAttemptSlice';
import { examAttemptApi } from './Api/examApi/ExamAttemptApi';
import { cronAgendaApi } from './Api/cronAgenda';

// Custom transform for encrypting and decrypting state
const createCryptoTransform = (secretKey) =>
  createTransform(
    // Transform state on its way to being serialized and persisted
    (inboundState) => {
      if (!inboundState) return inboundState;
      try {
        const encrypted = CryptoJS.AES.encrypt(
          JSON.stringify(inboundState),
          secretKey
        ).toString();
        return encrypted;
      } catch (error) {
        console.error('Encryption Error:', error);
        return inboundState;
      }
    },
    // Transform state on its way to being rehydrated
    (outboundState) => {
      if (!outboundState) return outboundState;
      try {
        const decryptedBytes = CryptoJS.AES.decrypt(outboundState, secretKey);
        const decrypted = decryptedBytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decrypted);
      } catch (error) {
        console.error('Decryption Error:', error);
        return outboundState;
      }
    }
  );

const authPersistConfig = {
  key: 'auth',
  storage,
  blacklist: ['_persist'],
  whitelist: ['token', 'user', 'isLoggedIn'],
  keyPrefix: '',
  transforms: [createCryptoTransform(process.env.REACT_APP_AUTH_SECRET_KEY)],
};
const cartPersistConfig = {
  key: 'cart',
  storage: storage,
  blacklist: ['_persist'],// to block the _persist object form the auth persist
  whitelist: ['cartItems'],//Only these item will store in localstorage
  keyPrefix: '',// Remove the "persist" prefix
  transforms: [createCryptoTransform(process.env.REACT_APP_CART_SECRET_KEY)],
};
const couponPersistConfig = {
  key: 'coupon',
  storage: storage,
  blacklist: ['_persist'],// to block the _persist object form the auth persist
  keyPrefix: '',// Remove the "persist" prefix
  transforms: [createCryptoTransform(process.env.REACT_APP_COUPON_SECRET_KEY)],
};

export const appReducers = combineReducers({
  auth: persistReducer(authPersistConfig, authSlice),
  cart: persistReducer(cartPersistConfig, cartSlice),
  coupon: persistReducer(couponPersistConfig, examCouponSlice),
})

const rootReducer = (state, action) => { //after logoout remove cart and root
  if (action.type === "auth/unsetCredentials") {
    // for all keys defined in your persistConfig(s)
    // storage.removeItem('cart')
    // storage.removeItem('root')
    // storage.removeItem('auth')
    return appReducers(undefined, action);
  }
  if (action.type === "auth/setCredentials") {
    // for all keys defined in your persistConfig(s)
    sessionStorage.setItem(process.env.REACT_APP_CART_ITEM_LOGIN_UPDATE_DB_NAME, process.env.REACT_APP_CART_ITEM_LOGIN_UPDATE_DB_VALUE);
    return appReducers(state, action);
  }
  if (sessionStorage.getItem(process.env.REACT_APP_CART_ITEM_LOGIN_UPDATE_DB_NAME)) {
    sessionStorage.removeItem(process.env.REACT_APP_CART_ITEM_LOGIN_UPDATE_DB_NAME);
  }
  return appReducers(state, action);
};

//store setup start form here 
const store = configureStore({
  reducer: {
    root: rootReducer,
    sideMenuOpenClose: SidebarHamburgerSlice,
    captcha: captchaSlice,
    [centralBaseApi.reducerPath]: centralBaseApi.reducer,

    [authApi.reducerPath]: authApi.reducer,

    [cartApi.reducerPath]: cartApi.reducer,

    user: userSlice,
    [userApi.reducerPath]: userApi.reducer,

    order: orderSlice,
    [orderApi.reducerPath]: orderApi.reducer,

    post: postSlice,
    [postApi.reducerPath]: postApi.reducer,

    examCreateStep: StepSlice,

    examPaperInfo: ExamPaperSlice,
    [ExamPaperApi.reducerPath]: ExamPaperApi.reducer,

    examSections: SectionSlice,
    [sectionsApi.reducerPath]: sectionsApi.reducer,

    examQuestions: QuestionSlice,
    [questionsApi.reducerPath]: questionsApi.reducer,

    examPaperType: PaperTypeSlice,
    [paperTypeApi.reducerPath]: paperTypeApi.reducer,

    [languageApi.reducerPath]: languageApi.reducer,

    [cronAgendaApi.reducerPath]: cronAgendaApi.reducer,

    examPage: ExamPageSlice,
    [examPageApi.reducerPath]: examPageApi.reducer,

    examPaperFilter: ExamPaperFilterSlice,
    [examPaperCatlogApi.reducerPath]: examPaperCatlogApi.reducer,

    homeSectionCat: ExamHomeSectionSlice,
    [examHomeSectionApi.reducerPath]: examHomeSectionApi.reducer,

    [examGlobalSearchApi.reducerPath]: examGlobalSearchApi.reducer,

    examPaperDetails: examPaperDetailsSlice,

    [couponApi.reducerPath]: couponApi.reducer,

    examInstructions: examInstructionsSlice,
    [examInstructionsApi.reducerPath]: examInstructionsApi.reducer,

    examTestLive: ExamTestLiveSlice,
    [examTestLiveApi.reducerPath]: examTestLiveApi.reducer,

    examAttempts: ExamAttemptSlice,
    [examAttemptApi.reducerPath]: examAttemptApi.reducer,

  },
  middleware: getDefaultMiddleware => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      authApi.middleware,
      centralBaseApi.middleware,
      cartApi.middleware,
      userApi.middleware,
      orderApi.middleware,
      postApi.middleware,
      ExamPaperApi.middleware,
      sectionsApi.middleware,
      questionsApi.middleware,
      languageApi.middleware,
      examPageApi.middleware,
      examPaperCatlogApi.middleware,
      examHomeSectionApi.middleware,
      examGlobalSearchApi.middleware,
      couponApi.middleware,
      examInstructionsApi.middleware,
      examTestLiveApi.middleware,
      examAttemptApi.middleware,
      cronAgendaApi.middleware,

    ]),
  ],
  devTools: process.env.NODE_ENV !== 'production',
});

const persistor = persistStore(store);
export { store, persistor };

setupListeners(store.dispatch);
