// export const api_url = 'http://localhost:8000/api';
// export const generatePublicUrl = (fileName) => {
//     return `http://localhost:8000/media/${fileName}`;
// }

// const baseUrl = "https://flipkart-rest-server.herokuapp.com";

const baseUrl =  process.env.REACT_APP_BACKEND_BASEURL;
export const apiUrl = `${baseUrl}/api`;
export const generatePublicUrl = (fileName, userId='userId', type) => {
  switch (type) {
    case 'product': return `${baseUrl}/media/product/${fileName}`;
    case 'profilePicture': return `${baseUrl}/secure/profile_img/${userId}/${fileName}`;
      // code block
      break;
    case 'category':
      // code block
      break;
    case 'postPic': return `${baseUrl}/media/userPosts/${fileName}`;

    default:
      return `${baseUrl}/media/${fileName}`;
  }

};
