import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const sectionsSlice = createSlice({
  name: 'sections',
  initialState,
  reducers: {
    addSection(state, action) {
      state.push(action.payload);
    },
    updateSection(state, action) {
      const { _id, ...updatedSectionData } = action.payload;
      const index = state.findIndex(sec => sec._id === _id);
      if (index !== -1) {
        state[index] = { ...state[index], ...updatedSectionData, _id }; // Ensure the _id remains the same
      }
    },
    deleteSection(state, action) {
      return state.filter(sec => sec._id !== action.payload);
    },
    resetSections(state) {
      return initialState;
    },
    setSections(state, action) {
      return action.payload;
    },
  },
});

export const { addSection, updateSection, deleteSection, resetSections, setSections } = sectionsSlice.actions;

export default sectionsSlice.reducer;
