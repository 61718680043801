import { centralBaseApi } from "../CentralBaseApi";

export const examPageApi = centralBaseApi.injectEndpoints({
    reducerPath: 'examPageApi',
    endpoints: (build) => ({
        getExamPageList: build.query({
            query: () => ({
                url: "/examp/allpage",
            }),
            providesTags: ['examPages']
        }),
        getExamPaperPage: build.query({
            query(payload) {
                const { cid, type } = payload;
                return {
                    url: `/examp/${cid}/${type}`,
                    method: 'GET',
                }
            },
        }),
        createExamPage: build.mutation({
            query: (form) => ({
                url: "/examp/create",
                method: "POST",
                body: form,
            }),
            invalidatesTags: ['examPages'],
        }),
        updateExamPage: build.mutation({
            query: (data) => ({
                url: "/examp/update",
                method: "POST",
                body: data,
                // credentials: "include",
            }),
            invalidatesTags: ["examPages"],
        }),
        deleteExamPageById: build.mutation({
            query: (id) => ({
                url: `/examp/${id}`,
                method: "DELETE"
                // credentials: "include",
            }),
            invalidatesTags: ["examPages"],
        }),
        overrideExisting: false,
    }),
});

export const {
    useCreateExamPageMutation,
    useGetExamPageListQuery,
    useUpdateExamPageMutation,
    useDeleteExamPageByIdMutation,
    useGetExamPaperPageQuery,
} = examPageApi;