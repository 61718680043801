import { createSlice } from '@reduxjs/toolkit';
import { centralBaseApi } from '../../Api/CentralBaseApi';

const initialState = {
  title: '',
  description: '',
  subjects: [],
  paperType: {
    mainExamNameId: '',
    mainExamName: '',
    subExamNames: [],
  },
  imgData: null,
  languages: [],
  sections: [],
  createExamStatus: 'draft',
  reviewStatus: 'pending',
  isPaid: false,
  costPrice: 0,
  sPrice: 0,
  cpspDiscount: 0,
  searchTag: [],
  status: 'idle',
  totalDuration: '',
  error: null,
};

const ExamPaperSlice = createSlice({
  name: 'examPaperSlice',
  initialState,
  reducers: {
    addExamPaperInfo(state, action) {
      return {
        ...state,
        ...action.payload,
        paperType: {
          ...state.paperType,
          ...action.payload.paperType,
        },
        subjects: action.payload.subjects?.length > 0 ? action.payload.subjects : state.subjects,
        languages: action.payload.paperLanguages?.length > 0 ? action.payload.paperLanguages : state.languages,
        sections: action.payload.sections?.length > 0 ? action.payload.sections : state.sections,
      };
    },
    updateExamPaperInfo(state, action) {
      return {
        ...state,
        ...action.payload,
        paperType: {
          ...state.paperType,
          ...action.payload.paperType,
        },
        subjects: action.payload.subjects?.length > 0 ? action.payload.subjects : state.subjects,
        languages: action.payload.paperLanguages?.length > 0 ? action.payload.paperLanguages : state.languages,
        sections: action.payload.sections?.length > 0 ? action.payload.sections : state.sections,
      };
    },
    addSearchTag(state, action) {
      const tag = action.payload.trim();
      if (tag && !state.searchTag.includes(tag)) {
        state.searchTag.push(tag); // Add tag only if not already in the array
      }
    },
    // Action to remove a tag from searchTag array
    removeSearchTag(state, action) {
      const tag = action.payload.trim();
      if (tag) {
        state.searchTag = state.searchTag.filter(t => t !== tag); // Remove tag from the array
      }
    },
    setUploadImgData(state, action) {
      state.imgData = action.payload;
    },
    resetExamPaperInfo() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(centralBaseApi.endpoints.createExamPaperInfo.matchPending, (state) => {
        return {
          ...state,
          status: 'loading',
        };
      })
      .addMatcher(centralBaseApi.endpoints.createExamPaperInfo.matchFulfilled, (state, action) => {
        return {
          ...initialState,
          ...action.payload,
          status: 'succeeded',
        };
      })
      .addMatcher(centralBaseApi.endpoints.createExamPaperInfo.matchRejected, (state, action) => {
        return {
          ...state,
          status: 'failed',
          error: action.error.message,
        };
      });
  },
});

export const { addExamPaperInfo, updateExamPaperInfo, resetExamPaperInfo, removeSearchTag, setUploadImgData, addSearchTag } = ExamPaperSlice.actions;

export default ExamPaperSlice.reducer;
