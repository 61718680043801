// couponSlice.js

import { createSlice } from '@reduxjs/toolkit';
import { centralBaseApi } from '../../Api/CentralBaseApi'; // Adjust the import path as needed

const initialState = {
  couponCode: '',
  discount: 0,
  discountType: '',
  isValid: false,
  status: 'idle',
  error: null,
};

const couponSlice = createSlice({
  name: 'couponSlice',
  initialState,
  reducers: {
    resetCoupon(state) {
      return initialState; // Reset to initial state
    },
    setCouponCode(state, action) {
      state.couponCode = action.payload; // Set coupon code
    },
    invalidateCoupon(state) {
      state.isValid = false; // Mark the coupon as invalid
      state.status = 'idle';
      state.discount = 0; // Reset discount
      state.discountType = ''; // Reset discount type
      state.couponCode = ''; // Clear coupon code
      state.error = null; // Clear any error
    },
    applyCouponSuccess(state, action) {
      const { code, discount, discountType } = action.payload;
      // Ensure valid discount values
      if (discount >= 0 && discount <= 100) {
        state.couponCode = code; // Set the coupon code
        state.discount = discount; // Set the discount value
        state.discountType = discountType; // Set the type of discount
        state.isValid = true; // Set coupon as valid
        state.status = 'succeeded'; // Set succeeded status
      } else {
        state.error = 'Invalid discount value'; // Handle invalid discount
        state.isValid = false; // Set coupon as invalid
      }
    },
  },
  extraReducers: (builder) => {
    // Apply coupon
    builder
      .addMatcher(centralBaseApi.endpoints.applyCoupon.matchPending, (state) => {
        state.status = 'loading'; // Set loading status
        state.error = null; // Reset previous errors
      })
      .addMatcher(centralBaseApi.endpoints.applyCoupon.matchFulfilled, (state, action) => {
        couponSlice.caseReducers.applyCouponSuccess(state, action); // Call applyCouponSuccess to handle fulfilled response
      })
      .addMatcher(centralBaseApi.endpoints.applyCoupon.matchRejected, (state, action) => {
        state.status = 'failed'; // Set failed status
        state.error = action.error.message || 'Failed to apply coupon'; // Set error message
        state.isValid = false; // Set coupon as invalid
      });
  },
});

export const { resetCoupon, setCouponCode, invalidateCoupon, applyCouponSuccess } = couponSlice.actions;

export default couponSlice.reducer;
