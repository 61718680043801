import { serializeFilters } from "../../../utils/serializeFilters";
import { centralBaseApi } from "../CentralBaseApi";

export const examPaperCatlogApi = centralBaseApi.injectEndpoints({
    reducerPath: 'examPaperCatlogApi',
    endpoints: (build) => ({
        // Combine filters and exam papers in one query
        getFilteredExamPapersWithFilters: build.query({
            query: (filters) => ({
                url: '/exam/fiter-catlog', // Combine endpoint for filters and papers
                method: 'POST',  // Use GET to handle filters and exam papers fetching
                body: serializeFilters(filters), // Serialize the filter parameters
            }),
            providesTags: ['examPapers', 'filterOptions'], // Cache filters and papers together
        }),

        // Update existing exam papers or filter data
        overrideExisting: false,
    }),
});

// Export the hooks for use in components
export const {
    useGetFilteredExamPapersWithFiltersQuery,
} = examPaperCatlogApi;
