import { centralBaseApi } from "./CentralBaseApi";

export const orderApi = centralBaseApi.injectEndpoints({
    reducerPath: 'orderApi',
    endpoints: (build) => ({
        getAddress: build.query({
            query: () => ({
                url: '/user/getaddress',
                method: 'GET'
            }),
            keepUnusedDataFor: 60,
            providesTags: ['address']
        }),

        addAddress: build.mutation({
            query: (payload) => ({
                url: '/user/address/create',
                method: 'POST',
                body: { payload }
            }),
            invalidatesTags: ['address'],
        }),
       // get all orders of a user
        getOrders: build.query({
            query: () => ({
                url: '/getorders',
                method: 'GET',
            }),
        }),
        // single order with complete info and delivery location
        getOrder: build.mutation({
            query: (payload) => ({
                url: '/getorder',
                method: 'POST',
                body:  payload 
            }),
            providesTags: ['order'],
        }),
        //after placing successfull order by user
        addOrder: build.mutation({
            query: (payload) => ({
                url: '/addorder',
                method: 'POST',
                body: payload
            }),
        }),
        overrideExisting: false,
    }),

});

export const {
    useGetAddressQuery,
    useAddAddressMutation,
    useGetOrdersQuery,
    useGetOrderMutation,
    useAddOrderMutation,
} = orderApi;