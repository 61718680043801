// src/store/examInstructionsSlice.js
import { createSlice } from '@reduxjs/toolkit';
import {
    examInstructionsApi,
} from '../../Api/examApi/ExamInstructionApi';

const initialState = {
    examInstructions: null,
    loading: false,
    error: null,
};

const examInstructionsSlice = createSlice({
    name: 'examInstructions',
    initialState,
    reducers: {
        setExamInstructions: (state, action) => {
            state.examInstructions = action.payload;
        },
        resetExamInstructions: (state) => {
            state.examInstructions = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                examInstructionsApi.endpoints.getExamInstructions.matchFulfilled,
                (state, action) => {
                    state.examInstructions = action.payload;
                }
            )
            .addMatcher(
                examInstructionsApi.endpoints.createExamInstructions.matchFulfilled,
                (state, action) => {
                    state.examInstructions = action.payload;
                }
            )
            .addMatcher(
                examInstructionsApi.endpoints.updateExamInstructions.matchFulfilled,
                (state, action) => {
                    state.examInstructions = action.payload;
                }
            )
            .addMatcher(
                examInstructionsApi.endpoints.deleteExamInstructions.matchFulfilled,
                (state) => {
                    state.examInstructions = null;
                }
            )
            .addMatcher(
                examInstructionsApi.endpoints.getExamInstructions.matchRejected,
                (state, action) => {
                    state.error = action.error.message;
                }
            )
            .addMatcher(
                examInstructionsApi.endpoints.createExamInstructions.matchRejected,
                (state, action) => {
                    state.error = action.error.message;
                }
            )
            .addMatcher(
                examInstructionsApi.endpoints.updateExamInstructions.matchRejected,
                (state, action) => {
                    state.error = action.error.message;
                }
            )
            .addMatcher(
                examInstructionsApi.endpoints.deleteExamInstructions.matchRejected,
                (state, action) => {
                    state.error = action.error.message;
                }
            );
    },
});

export const { setExamInstructions, resetExamInstructions } = examInstructionsSlice.actions;

export default examInstructionsSlice.reducer;
