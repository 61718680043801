import { createSlice } from '@reduxjs/toolkit';
import { examPaperCatlogApi } from '../../Api/examApi/ExamPaperCatlogApi';

const initialState = {
    activeFilters: [],
    filterOptions: {
        byExamName: [],
        prices: {
            minPrice: null,
            maxPrice: null
        },
        createdBy: [],
        isPaidOptions: [],
        ratings: []
    },
    filters: {
        priceMin: null,
        priceMax: null,
        isPaid: false,
        isFree: false,
        reviewStatus: '',
        sortBy: 'price',  // Default sorting option
        page: null,
        limit: 20,
        durationMin: null,
        durationMax: null,
        languages: [],
        createExamStatus: '',
        ratingMin: null,
        byExamName: '',
        createdBy: ''
    },
    catalog: [],
    meta: {
        totalExamPapers: 0,
        totalPages: 0,
        currentPage: 1,
        perPage: 20,
        nextPage: null,
        prevPage: null
    },
    sortOptions: [], // Default sorting
    status: 'idle',
    error: null,
};

const examPaperFilterSlice = createSlice({
    name: 'examPaperFilter',
    initialState,
    reducers: {
        setFilters(state, action) {
            const newFilters = action.payload;

            // Update the existing filters
            state.filters = { ...state.filters, ...newFilters };

            // Update active filters, ensuring that if the filter key exists, it updates the value
            state.activeFilters = Object.keys(newFilters).reduce((acc, key) => {
                const filterValue = newFilters[key];

                // Check if the filter value is valid (not empty, not null, not false)
                if (filterValue !== '' && filterValue !== null && filterValue !== false) {
                    // Find if the filter already exists in activeFilters
                    const existingFilterIndex = acc.findIndex(filter => filter.key === key);

                    if (existingFilterIndex !== -1) {
                        // If the filter already exists, update its value
                        acc[existingFilterIndex].value = filterValue;
                    } else {
                        // If the filter doesn't exist, add it
                        acc.push({ key, value: filterValue });
                    }
                } else {
                    // If the filter value is not valid, remove it from active filters
                    acc = acc.filter(filter => filter.key !== key);
                }

                return acc;
            }, [...state.activeFilters]);
        },


        removeFilter(state, action) {
            const { key } = action.payload;

            // Ensure filters and activeFilters are defined
            if (!state.filters) {
                return;
            }
            if (!state.activeFilters) {
                return;
            }

            // Reset filter if key exists
            if (state.filters.hasOwnProperty(key)) {
                state.filters[key] = initialState.filters[key];
                state.activeFilters = state.activeFilters.filter(filter => filter.key !== key);
            }
        },
        setFreeOrPaid(state, action) {
            // Ensure filters object exists
            if (!state.filters) {
                state.filters = {};
            }
            const { isFree, isPaid } = action.payload;

            // Update only the relevant properties
            if (isFree !== undefined) {
                state.filters.isFree = isFree;
            }
            if (isPaid !== undefined) {
                state.filters.isPaid = isPaid;
            }
        },

        resetFilters(state) {
            state.filters = { ...initialState.filters };
            state.activeFilters = [];
        },

        setFilterOptions(state, action) {
            state.filterOptions = action.payload;
        },

        setCatalog(state, action) {
            state.catalog = action.payload;
        },

        setFilterMeta(state, action) {
            state.meta = action.payload;
        },
        setPage(state, action) {
            state.filters.page = action.payload;
        },
        setSortOptions(state, action) {
            if (state.sortOptions.length <= 0) {
                state.sortOptions = action.payload;
            } else {
                const sortOptionsArray = action.payload;
                state.sortOptions = sortOptionsArray;
                // Set the default active sort option (e.g., 'newest')
                const defaultSortOption = sortOptionsArray.find(option => option.value === 'newest');
                state.filters.sortBy = defaultSortOption ? defaultSortOption.value : 'newest';

                state.filters.page = 1;  // Reset page when sorting changes
            }

        },
        // extraReducers: (builder) => {
        //     builder
        //         .addMatcher(examPaperCatlogApi.endpoints.getFilteredExamPapersWithFilters.matchPending, (state) => {
        //             state.status = 'loading';
        //             state.error = null;
        //         })
        //         .addMatcher(examPaperCatlogApi.endpoints.getFilteredExamPapersWithFilters.matchFulfilled, (state, action) => {
        //             const { catalog, meta, filterOptions, sortOptions, activeSortBy } = action.payload.data;
        //             state.catalog = catalog;
        //             state.meta = meta;
        //             state.filterOptions = filterOptions;
        //             state.sortOptions = sortOptions;
        //             state.filters.sortBy = activeSortBy;
        //             state.status = 'succeeded';
        //         })
        //         .addMatcher(examPaperCatlogApi.endpoints.getFilteredExamPapersWithFilters.matchRejected, (state, action) => {
        //             state.status = 'failed';
        //             state.error = action.error.message;
        //         });
        // },
    }
});

export const { setFilters, resetFilters, setFilterOptions, setCatalog, setFilterMeta, removeFilter, setFreeOrPaid, setPage, setSortOptions } = examPaperFilterSlice.actions;

export default examPaperFilterSlice.reducer;
