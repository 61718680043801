import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    cartItems: [], // Cart items stored in an array for multiple exam papers
};

export const cartSlice = createSlice({
    name: 'cartSlice',
    initialState,
    reducers: {
        setToCart: (state, action) => {
            const cartItems = Array.isArray(action.payload) ? action.payload : []; // Ensure cartItems is an array

            // Ensure state.cartItems is initialized as an array
            if (!Array.isArray(state.cartItems)) {
                state.cartItems = [];
            }

            cartItems.forEach(item => {
                const {
                    _id,
                    price,
                    languages = [],
                    createdBy,
                    paperType = {},
                    totalDuration: duration,
                    productImgUrl = [],
                    title,
                    currency,
                    discount = 0,
                    isPaid,
                    country,
                } = item;

                // Ensure cartItems array is initialized before using findIndex
                const existingCartItemIndex = state.cartItems.findIndex(existingItem => existingItem._id === _id);

                // If the product already exists in the cart, increment the quantity
                if (existingCartItemIndex !== -1) {
                    state.cartItems[existingCartItemIndex].quantity += 1; // Increment existing quantity by 1
                }
                // Otherwise, add a new product to the cart with quantity set to 1
                else {
                    state.cartItems.push({
                        _id,
                        title,           // Store the title
                        price,           // Lock the price at the time of adding to cart
                        languages,       // Store languages array
                        createdBy,       // Store who created the paper
                        quantity: 1,     // Set initial quantity to 1 when adding a new item
                        currency,        // Store the currency for this product
                        discount,        // Store any discount applied
                        isPaid,          // Store whether the product is paid or free
                        paperType,       // Store the paper type (main exam name and sub exam names)
                        duration,        // Store the total duration of the exam paper
                        country,         // Ensure that this exam is for the correct country
                        productImgUrl,   // Store product image URL(s)
                    });
                }
            });
        },
        setToCartWithApiData: (state, action) => {
            // Ensure cartItems is an array from the API response
            const cartItems = Array.isArray(action.payload) ? action.payload : [];
            // Replace the entire cartItems in state with the API response
            state.cartItems = cartItems.map(item => {
                const {
                    _id,
                    quantity,       // Use quantity from the payload
                    price,
                    languages = [],
                    createdBy,
                    paperType = {},
                    totalDuration: duration,
                    productImgUrl = [],
                    title,
                    currency,
                    discount = 0,
                    isPaid,
                    country,
                } = item;

                return {
                    _id,
                    title,           // Store the title
                    price,           // Lock the price at the time of adding to cart
                    languages,       // Store languages array
                    createdBy,       // Store who created the paper
                    quantity,        // Set quantity from the payload
                    currency,        // Store the currency for this product
                    discount,        // Store any discount applied
                    isPaid,          // Store whether the product is paid or free
                    paperType,       // Store the paper type (main exam name and sub exam names)
                    duration,        // Store the total duration of the exam paper
                    country,         // Ensure that this exam is for the correct country
                    productImgUrl,   // Store product image URL(s)
                };
            });
        },

        incrementQuantity: (state, action) => {
            const { _id } = action.payload; // Destructure to use consistent naming
            const existingCartItem = state.cartItems.find(item => item._id === _id);

            if (existingCartItem) {
                existingCartItem.quantity += 1; // Increment quantity
            }
        },

        decrementQuantity: (state, action) => {
            const { _id } = action.payload; // Destructure to use consistent naming
            const existingCartItem = state.cartItems.find(item => item._id === _id);

            if (existingCartItem && existingCartItem.quantity > 1) {
                existingCartItem.quantity -= 1; // Decrement quantity if greater than 1
            }
        },

        updateToCart: (state, action) => {
            state.cartItems = action.payload.cartItems; // Replace the entire cart items array
        },

        removeItem: (state, action) => {
            const { _id } = action.payload; // Destructure to use consistent naming
            state.cartItems = state.cartItems.filter(item => item._id !== _id); // Remove the product from cart
            if (state.cartItems.length === 0) {
                state.cartItems = [];
            }
        },

        resetToCart: (state) => {
            state.cartItems = []; // Reset the cart to an empty state
        },
    },
});

// Export actions and reducer
export const {
    setToCart,
    setToCartWithApiData,
    updateToCart,
    resetToCart,
    incrementQuantity,
    decrementQuantity,
    removeItem,
} = cartSlice.actions;

export default cartSlice.reducer;
