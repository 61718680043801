import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    paperTypes: {},
};

const paperTypeSlice = createSlice({
    name: 'paperType',
    initialState,
    reducers: {
        setCurrentPaperType: (state, action) => {
            state.paperTypes = action.payload;
        },
        clearCurrentPaperType: (state) => {
            state.paperTypes = {};
        },
    },
});

export const { setCurrentPaperType, clearCurrentPaperType } = paperTypeSlice.actions;

export default paperTypeSlice.reducer;
