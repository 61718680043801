import { centralBaseApi } from "../CentralBaseApi";

export const examHomeSectionApi = centralBaseApi.injectEndpoints({
    reducerPath: 'examHomeSection',
    endpoints: (build) => ({
        getHomeSectionsCatData: build.query({
            query: () => ({
                url: `/homesec`,
                method: 'GET',
            }),
        }),
        getOneHomeSectionCatlog: build.query({
            query: (sname) => ({
                url: `/homesec/catlog/${sname}`,
                method: 'GET',
            }),
        }),
        overrideExisting: false,
    }),
});

export const {
    useGetHomeSectionsCatDataQuery,
    useGetOneHomeSectionCatlogQuery,
} = examHomeSectionApi;

