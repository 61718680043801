import { createSlice } from '@reduxjs/toolkit';
// Helper function to calculate indicatorData based on the status of the questions in the section
const calculateIndicatorData = (sectionId, questions) => {
  let answered = 0;
  let notanswered = 0;
  let notvisited = 0;
  let markedreview = 0;
  let answeredmarkedreview = 0;

  // Loop through all the questions and calculate counts based on their status
  Object.values(questions).forEach(question => {
    if (question.sectionId === sectionId) {
      switch (question.questionStatus) {
        case 'answered':
          answered++;
          break;
        case 'notanswered':
          notanswered++;
          break;
        case 'notvisited':
          notvisited++;
          break;
        case 'markedreview':
          markedreview++;
          break;
        case 'answeredmarkedreview':
          answeredmarkedreview++;
          break;
        default:
          notvisited++; // Default to notvisited if status is not found
          break;
      }
    }
  });

  return {
    answered,
    notanswered,
    notvisited,
    markedreview,
    answeredmarkedreview,
  };
};

const initialState = {
  examDetails: {
    examName: null,
    paperSetData: [],
    attemptMsg: null,
    examDescription: null,
    totalDuration: null,
    remainingTime: null,
    sections: {},
    questions: {},
    languages: [],
    viewLanguage: null,
    startTime: null,
    endTime: null,
  },
  timer: {
    isExamStarted: false,
    isExamExpired: false,
  },
};

const examTestLiveSlice = createSlice({
  name: 'examTestLiveSlice',
  initialState,
  reducers: {
    setExamDetails(state, action) {
      const {
        _id,
        examName,
        paperSetData,
        sections,
        questions,
        totalDuration,
        remainingTime,
        startTime,
        endTime,
        languages,
        attemptMsg,
      } = action.payload;

      // Merge new data from API while preserving user actions
      state.examDetails = {
        ...state.examDetails,
        _id,
        examName,
        attemptMsg,
        paperSetData,
        totalDuration,
        remainingTime,
        startTime,
        endTime,
        languages,
        sections: sections.reduce((acc, section) => {
          acc[section.id] = {
            ...section,
            indicatorData: calculateIndicatorData(section.id, state.examDetails.questions),
          };
          return acc;
        }, {}),
        questions: questions.reduce((acc, question) => {
          const existingQuestion = state.examDetails.questions[question.id];
          acc[question.id] = {
            ...question,
            // Preserve only necessary actions
            questionStatus: existingQuestion?.questionStatus || 'notvisited',
            selectedOption: existingQuestion?.selectedOption || null,
          };
          return acc;
        }, {}),
      };
      if (!state.examDetails.viewLanguage && languages.length > 0) {
        state.examDetails.viewLanguage = languages[0];
      }
    },

    updateQuestion(state, action) {
      const { sectionId, questionId, status, selectedOption = null } = action.payload;
      const section = state.examDetails.sections[sectionId];
      const question = state.examDetails.questions[questionId];

      if (section && question) {
        // Handle status transitions
        if (status === 'answered') {
          question.questionStatus = 'answered';
          question.selectedOption = selectedOption;
        } else if (status === 'notanswered') {
          question.questionStatus = 'notanswered';
          question.selectedOption = selectedOption;
        } else if (status === 'markedreview') {
          question.questionStatus = 'markedreview';
        } else if (status === 'answeredmarkedreview') {
          question.questionStatus = 'answeredmarkedreview';
          question.selectedOption = selectedOption;
        }

        // Recalculate indicatorData for the section
        section.indicatorData = calculateIndicatorData(sectionId, state.examDetails.questions);
      }
    },

    startExam(state) {
      state.timer.isExamStarted = true;
      state.timer.isExamExpired = false;
    },

    decrementTime(state) {
      if (state.examDetails.remainingTime > 0) {
        state.examDetails.remainingTime--;
      } else {
        state.timer.isExamExpired = true;
      }
    },

    resetTimer(state) {
      state.examDetails.remainingTime = state.examDetails.totalDuration;
      state.timer.isExamStarted = false;
      state.timer.isExamExpired = false;
    },

    resetExamData(state) {
      // Reset to initial state while preserving question user actions
      const preservedQuestions = Object.keys(state.examDetails.questions).reduce((acc, questionId) => {
        const question = state.examDetails.questions[questionId];
        if (question.questionStatus !== 'notvisited') {
          acc[questionId] = question;
        }
        return acc;
      }, {});

      state.examDetails = {
        ...initialState.examDetails,
        questions: preservedQuestions,
      };

      state.timer = initialState.timer;
    },
    setViewLanguage(state, action) {
      const selectedLanguage = action.payload;
      if (state.examDetails.languages.includes(selectedLanguage)) {
        state.examDetails.viewLanguage = selectedLanguage;
      }
    },
    resetLiveExamData(state){
      return initialState;
    }
  },
});

export const {
  setExamDetails,
  updateQuestion,
  startExam,
  decrementTime,
  resetTimer,
  resetExamData,
  setViewLanguage,
  resetLiveExamData,
} = examTestLiveSlice.actions;

export default examTestLiveSlice.reducer;
