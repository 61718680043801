import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  examPaperSuggest: [],  // Array for storing suggested exam papers
  examPaperReview: {},   // Object for storing review details of the exam paper
  examPaperDetails: {},  // Object for storing the details of a specific exam paper
};

export const examPaperDetailsSlice = createSlice({
  name: 'examPaperDetails', // Provide a simple and concise name for the slice
  initialState,
  reducers: {
    // Action to set the details of a specific exam paper
    setExamPaperDetails: (state, action) => {
      state.examPaperDetails = action.payload;
    },

    // Action to set exam paper suggestions
    setExamPaperSuggestions: (state, action) => {
      state.examPaperSuggest = action.payload;
    },

    // Action to set exam paper review details
    setExamPaperReview: (state, action) => {
      state.examPaperReview = action.payload;
    },

    // Action to reset all details to their initial state
    resetExamPaperDetailsState: () => initialState,
  },
});

// Export actions for use in components or middleware
export const {
  setExamPaperDetails,
  setExamPaperSuggestions,
  setExamPaperReview,
  resetExamPaperDetailsState,
} = examPaperDetailsSlice.actions;

// Export the reducer to be added to the store
export default examPaperDetailsSlice.reducer;
