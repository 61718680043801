import React from 'react';
import { NavLink } from 'react-router-dom';
import { ListGroup, Accordion} from 'react-bootstrap';

const Adleftbaritem = (props) => {
    const { name, subMenus, to, exact, menuIcon, menuItem_id } = props;
    return (
        <>
            <Accordion.Item eventKey={menuItem_id} >
                <NavLink {...exact} to={to}>
                    <Accordion.Header>                             
                        <div className="ad-nameicon">
                        <div className="ad-menu-icon">{menuIcon}</div>
                        <span className="ad-iconname">{name}</span>
                        </div> 
                        {/* {menuItem_id ===1 ?
                        <div className={`.ad-leftbarcanv accordion-button collapsed ${1 ? 'inactive' :''}`}></div> */}
                       
                         {/* <div className={`ad-leftbarcanv .accordion-button:not(.collapsed)::after ${toggle_id ? 'inactive' : ' '}`} >
                        </div> :''}    */}
                        
                    </Accordion.Header>
                </NavLink>
                <Accordion.Body>
                    <ListGroup>
                        {subMenus && subMenus.length > 0 ? (
                            <>
                                {subMenus.map((menu, index) => (
                                    <div key={index}>
                                        <NavLink to={menu.to}>
                                            <ListGroup.Item action >
                                                {menu.SubMenuIcons}{menu.name}
                                            </ListGroup.Item>
                                        </NavLink>
                                    </div>
                                ))}
                            </>
                        ) : null}
                    </ListGroup>
                </Accordion.Body>
            </Accordion.Item>
        </>
    )
}

export default Adleftbaritem
