import React from 'react';
import { Button } from 'react-bootstrap';
import Logo404 from '../_assests/404.png';
import { useNavigate } from "react-router-dom";
import './404.css';
const PageNotFound = () => {
    const navigate = useNavigate();

    const goBack = () => {
        if (window.history.length > 2) {
            navigate(-1); // Go back to the previous page if it exists
        } else {
            navigate('/'); // Otherwise, navigate to the home page or a fallback route
        }
    }
      
    return (
        <div className="img_404_body">
            <div className="img_404" >
                <img className='img-404-center' src={Logo404} alt="" />
                <div className="img_set mb-3">
                    <Button onClick={goBack} variant="primary">Go Back</Button>
                </div>
            </div>
        </div >
    )
}

export default PageNotFound